<template>
  <section class="Services">
    <div class="Container">
      <h1 class="Title-h3 Services-Title">Services</h1>
      <div class="Services-List">
        <div
            class="Services-Item"
            v-for="(info, index) in listInfo"
            :key="index"
        >
          <img :src="require(`@/assets/images/${info.img}`)" alt="" class="Services-ItemImg">
          <div class="Services-ItemTitle">{{ info.title }}</div>
          <p class="Text" v-html="info.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      listInfo: [
        {
          img: "megafon.svg",
          title: "DIGITAL COMPETITIVE ANALYSIS",
          text: "With a digital competitive analysis from Loop limited, you'll get a market analysis of your website and some competitors. <br><br>This provides a full passage to improve your website's SEO and PPC performance and generate more leads and sales. <br><br>With a thorough competitive analysis from Blue Corona, we will identify the Strengths, Weaknesses, Threats, and Opportunities from a digital marketing perspective and provide custom-tailored recommendations for each section above on how you can improve your online presence and performance."
        },
        {
          img: "rocket.svg",
          title: "LEAD NURTURE MARKETING",
          text: "Upgrade your digital marketing channel through high-quality push marketing on relevant and popular social media platforms.<br><br> A successful lead nurturing program focuses communication and marketing efforts by listening to the needs of prospective customers and providing the information they need to increase brand awareness, build trust, and maintain a connection until they are ready to make a purchase."
        },
        {
          img: "diamond.svg",
          title: "EMAIL MARKETING",
          text: "Our team handles the copywriting, design, and your customer list segmentation while you generate more sales and leads. <br><br>Our professional email marketers help your business stay on their mind with your prospective and existing customers by sending custom email newsletters every month. <br><br>Our email marketing campaigns are cost-effective, simple, efficient, and read by relevant eyes. Drip email marketing, opt-in email marketing, and marketing automation from Loop limited is a low-cost way to increase up-sells, leads, referrals, and sales!",
        },
        {
          img: "bar.svg",
          title: "PAID ADVERTISING",
          text: "We integrate your tracking platform, marketing analytics, and CRM with our end-to-end tracking solution to drive definite business value for our clients. <br><br>Running paid advertising campaigns, you are able to reach a very large audience.<br><br> Once a visitor has browsed your website or clicked through an ad, you can run a retargeting campaign to continuously engage prospective customers who have shown an interest in your service or products. With this, you are constantly in the back of the mind of your potential customers."
        },
        {
          img: "mail.svg",
          title: "CREATIVE SERVICES",
          text: "Our department does creative work such as production, writing, and design.<br><br> Our in-house copywriters target the right keywords, making your website more visible on search engine result pages because they have written thousands of web pages for outsourcers."
        },
        {
          img: "darts.svg",
          title: "WEBSITE OPTIMIZATION",
          text: "\"Optimizing your website helps you to start building a relationship gaining your visitors’ trust and lets you sell products without having sales calls. <br><br>Increasing the conversion rates on your website and landing pages will get you more revenue from the site traffic you’re already getting.<br><br> When you optimize for search, you put yourself on top. Consumers will search for relevant terms, and your website and business will always show up. \""
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Services {
  padding: 200px 0 150px;

  &-Title {
    margin-bottom: 52px;
    padding-left: 80px;
    color: var(--color-text-main2);
  }

  &-List {

  }

  &-Item {
    margin-bottom: 30px;
    padding: 40px 32px;
    border-radius: 4px;
    background-color: var(--color-text-main3);
    box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-ItemImg {
    margin-bottom: 30px;
  }

  &-ItemTitle {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.23;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
</style>